import React, { Component } from 'react';
import Page from '../components/Page';
import Container from '../components/styles/Container';
import Text from '../components/styles/Text';

class garage extends Component {
  render() {
    return (
      <div>
        <Page>
          <Container>
            <div
              dangerouslySetInnerHTML={{
                __html: `
              <h1 class="entry-title main_title">Modarri Privacy Policy</h1>


					<div class="entry-content">
					<h3 id="ppHeader">modarri.com Privacy Policy</h3>
<div id="ppBody">
<div class="innerText">We do not collect information from visitors of our site unless provided by the user through a form.</div>
</div>
<p>&nbsp;</p>
<div class="grayText"><strong>When do we collect information?</strong></div>
<div class="grayText"></div>
<div class="innerText">When a user fills out a form or purchases a product from our website.</div>
<p>&nbsp;</p>
<div class="grayText"><strong>How do we use your information?</strong></div>
<p>&nbsp;</p>
<div class="innerText">
<p>We may use the information we collect from you when you register, make a purchase, sign up for our newsletter, respond to a survey or marketing communication, surf the website, or use certain other site features in the following ways:</p>
</div>
<p><span id="infoPro"></span>-To contact the user about a purchase or product</p>
<div class="grayText"><strong>How do we protect your information?</strong></div>
<p>&nbsp;</p>
<div class="innerText">
<p>We use regular Malware Scanning.</p>
</div>
<div class="innerText">Your personal information is contained behind secured networks and is only accessible by a limited number of persons who have special access rights to such systems, and are required to keep the information confidential. In addition, all sensitive/credit information you supply is encrypted via Secure Socket Layer (SSL) technology.</div>
<p>&nbsp;</p>
<div class="innerText">We implement a variety of security measures when a user places an order enters, submits, or accesses their information to maintain the safety of your personal information.</div>
<p>&nbsp;</p>
<div class="innerText">All transactions are processed through a gateway provider and are not stored or processed on our servers.</div>
<p>&nbsp;</p>
<div class="grayText"><strong>Do we use cookies?</strong></div>
<p>&nbsp;</p>
<div class="innerText">Yes. Cookies are small files that a site or its service provider transfers to your computers hard drive through your Web browser (if you allow) that enables the sites or service providers systems to recognize your browser and capture and remember certain information. For instance, we use cookies to help us remember and process the items in your shopping cart. They are also used to help us understand your preferences based on previous or current site activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about site traffic and site interaction so that we can offer better site experiences and tools in the future.</div>
<div class="innerText"><strong>We use cookies to:</strong></div>
<div class="innerText">      <strong>•</strong> Help remember and process the items in the shopping cart.</div>
<div class="innerText">      <strong>•</strong> Understand and save users preferences for future visits.</div>
<div class="innerText">      <strong>•</strong> Compile aggregate data about site traffic and site interactions in order to offer better site experiences and tools in the future. We may also use trusted third-party services that track this information on our behalf.</div>
<div class="innerText">You can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. Since browser is a little different, look at your browsers Help Menu to learn the correct way to modify your cookies.</div>
<p>&nbsp;</p>
<div class="innerText">If you turn cookies off, some features will be disabled. It won't affect the users experience that make your site experience more efficient and may not function properly.</div>
<p>&nbsp;</p>
<div class="innerText">However, you will still be able to place orders .</div>
<p>&nbsp;</p>
<div class="grayText"><strong>Third-party disclosure</strong></div>
<p>&nbsp;</p>
<div class="innerText">We do not sell, trade, or otherwise transfer to outside parties your Personally Identifiable Information.</div>
<p>&nbsp;</p>
<div class="grayText"><strong>Third-party links</strong></div>
<p>&nbsp;</p>
<div class="innerText">We do not include or offer third-party products or services on our website.</div>
<p>&nbsp;</p>
<div class="blueText"><strong>Google</strong></div>
<p>&nbsp;</p>
<div class="innerText">
<p>Google's advertising requirements can be summed up by Googles Advertising Principles. They are put in place to provide a positive experience for users. https://support.google.com/adwordspolicy/answer/1316548?hl=en</p>
</div>
<div class="innerText">We use Google AdSense Advertising on our website.</div>
<div class="innerText">Google, as a third-party vendor, uses cookies to serve ads on our site. Googles use of the DART cookie enables it to serve ads to our users based on previous visits to our site and other sites on the Internet. Users may opt-out of the use of the DART cookie by visiting the Google Ad and Content Network privacy policy.</div>
<div class="innerText"><strong>We have implemented the following:</strong></div>
<div class="innerText">      <strong>•</strong> Demographics and Interests Reporting</div>
<p>&nbsp;</p>
<div class="innerText">We, along with third-party vendors such as Google use first-party cookies (such as the Google Analytics cookies) and third-party cookies (such as the DoubleClick cookie) or other third-party identifiers together to compile data regarding user interactions with ad impressions and other ad service functions as they relate to our website.</div>
<div class="innerText"><strong>Opting out:<br />
Users can set preferences for how Google advertises to you using the Google Ad Settings page. Alternatively, you can opt out by visiting the Network Advertising Initiative Opt Out page or by using the Google Analytics Opt Out Browser add on.</strong></div>
<p><strong><span id="calOppa"></span><br />
</strong></p>
<div class="blueText"><strong>California Online Privacy Protection Act</strong></div>
<p><strong> </strong></p>
<div class="innerText">CalOPPA is the first state law in the nation to require commercial websites and online services to post a privacy policy. The law&#8217;s reach stretches well beyond California to require any person or company in the United States (and conceivably the world) that operates websites collecting Personally Identifiable Information from California consumers to post a conspicuous privacy policy on its website stating exactly the information being collected and those individuals or companies with whom it is being shared. &#8211; See more at: http://consumercal.org/california-online-privacy-protection-act-caloppa/#sthash.0FdRbT51.dpuf</div>
<div class="innerText"><strong>According to CalOPPA, we agree to the following:</strong></div>
<div class="innerText">Users can visit our site anonymously.</div>
<div class="innerText">Once this privacy policy is created, we will add a link to it on our home page or as a minimum, on the first significant page after entering our website.</div>
<div class="innerText">Our Privacy Policy link includes the word Privacy; and can easily be found on the page specified above.</div>
<div class="innerText">You will be notified of any Privacy Policy changes:</div>
<div class="innerText">      <strong>•</strong> On our Privacy Policy Page</div>
<div class="innerText">Can change your personal information:</div>
<div class="innerText">      <strong>•</strong> By emailing us</div>
<div class="innerText"><strong>How does our site handle Do Not Track signals?</strong></div>
<div class="innerText">We honor Do Not Track signals and Do Not Track, plant cookies, or use advertising when a Do Not Track (DNT) browser mechanism is in place.</div>
<div class="innerText"><strong>Does our site allow third-party behavioral tracking?</strong></div>
<div class="innerText">Its also important to note that we do not allow third-party behavioral tracking</div>
<p><strong><span id="coppAct"></span><br />
</strong></p>
<div class="blueText"><strong>COPPA (Children Online Privacy Protection Act)</strong></div>
<p><strong> </strong></p>
<div class="innerText">
<p>When it comes to the collection of personal information from children under the age of 13 years old, the Childrens Online Privacy Protection Act (COPPA) puts parents in control. The Federal Trade Commission, United States; consumer protection agency, enforces the COPPA Rule, which spells out what operators of websites and online services must do to protect children's privacy and safety online.</p>
</div>
<div class="innerText">We do not specifically market to children under the age of 13 years old.</div>
<p><strong><span id="ftcFip"></span><br />
</strong></p>
<div class="blueText"><strong>Fair Information Practices</strong></div>
<p><strong> </strong></p>
<div class="innerText">
<p>The Fair Information Practices Principles form the backbone of privacy law in the United States and the concepts they include have played a significant role in the development of data protection laws around the globe. Understanding the Fair Information Practice Principles and how they should be implemented is critical to comply with the various privacy laws that protect personal information.</p>
</div>
<div class="innerText"><strong>In order to be in line with Fair Information Practices we will take the following responsive action, should a data breach occur:</strong></div>
<div class="innerText">We will notify you via email</div>
<div class="innerText">      <strong>•</strong> Within 7 business days</div>
<div class="innerText">We will notify the users via in-site notification</div>
<div class="innerText">      <strong>•</strong> Within 7 business days</div>
<div class="innerText">We also agree to the Individual Redress Principle which requires that individuals have the right to legally pursue enforceable rights against data collectors and processors who fail to adhere to the law. This principle requires not only that individuals have enforceable rights against data users, but also that individuals have recourse to courts or government agencies to investigate and/or prosecute non-compliance by data processors.</div>
<p><strong><span id="canSpam"></span><br />
</strong></p>
<div class="blueText"><strong>CAN SPAM Act</strong></div>
<p><strong> </strong></p>
<div class="innerText">
<p>The CAN-SPAM Act is a law that sets the rules for commercial email, establishes requirements for commercial messages, gives recipients the right to have emails stopped from being sent to them, and spells out tough penalties for violations.</p>
</div>
<div class="innerText"><strong>We collect your email address in order to:</strong></div>
<div class="innerText">      <strong>•</strong> Send information, respond to inquiries, and/or other requests or questions</div>
<div class="innerText">      <strong>•</strong> Process orders and to send information and updates pertaining to orders.</div>
<div class="innerText">      <strong>•</strong> Send you additional information related to your product and/or service</div>
<div class="innerText">      <strong>•</strong> Market to our mailing list or continue to send emails to our clients after the original transaction has occurred.</div>
<div class="innerText"><strong>To be in accordance with CANSPAM, we agree to the following:</strong></div>
<div class="innerText">      <strong>•</strong> Not use false or misleading subjects or email addresses.</div>
<div class="innerText">      <strong>•</strong> Identify the message as an advertisement in some reasonable way.</div>
<div class="innerText">      <strong>•</strong> Include the physical address of our business or site headquarters.</div>
<div class="innerText">      <strong>•</strong> Monitor third-party email marketing services for compliance, if one is used.</div>
<div class="innerText">      <strong>•</strong> Honor opt-out/unsubscribe requests quickly.</div>
<div class="innerText">      <strong>•</strong> Allow users to unsubscribe by using the link at the bottom of each email.</div>
<div class="innerText"><strong><br />
If at any time you would like to unsubscribe from receiving future emails, you can email us at</strong></div>
<div class="innerText">      <strong>•</strong> Follow the instructions at the bottom of each email.</div>
<p><strong>and we will promptly remove you from ALL correspondence.<br />
<span id="ourCon"></span><br />
</strong></p>
<div class="blueText"><strong>Contacting Us</strong></div>
<p><strong> </strong></p>
<div class="innerText">
<p>If there are any questions regarding this privacy policy, you may contact us using the information below.</p>
</div>
<div class="innerText">modarri.com</div>
<div class="innerText">303 Potrero Street</div>
<p><strong>Santa Cruz, California 95060</strong></p>
<div class="innerText">United States</div>
<div class="innerText">support@thoughtfulltoys.com</div>
<div class="innerText">831-421-2363</div>
<div class="innerText">Last Edited on 2016-10-18</div>
<p>&nbsp;</p>`,
              }}
            />
          </Container>
        </Page>
      </div>
    );
  }
}

export default garage;
